import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./Layout"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import * as style from "./category-archives.module.css"

export const query = graphql`
  query ($id: [ID]) {
    wpgraphql {
      posts(first: 500, where: { categoryIn: $id }) {
        nodes {
          id
          title
          slug
          uri
          insights {
            contentType
          }
          insights {
            hideFromListings
          }
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphUrl
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphImage {
              mimeType
              altText
              mediaDetails {
                file
              }
            }
          }
          featuredImage {
            node {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
        }
      }
    }
  }
`

const CatArchivesTemplate = ({ data, pageContext }) => {
  const insights = data.wpgraphql.posts.nodes

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const isSafariV16 = isSafariLess16()

  return (
    <Layout>
      <div className="hero-small d-flex align-items-center darkgrey_bg mb-100">
        <div className="container">
          <div className="row">
            <div
              id="hide-row-when-nav-open"
              className="col-12 color-white animated-headline"
            >
              <span className="h4 d-block text-uppercase mb-3 header-fade-in">
                Insights
              </span>
              <div className="animated-headline-wrap">
                <h1 className="new_style">
                  <span
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  >
                    {pageContext.name}
                  </span>
                </h1>
              </div>
              <div className="mt-4 h4 header-fade-in position-absolute bottom-0 pb-5">
                <Link
                  to="/insights/"
                  className="ani_grey_back_arrow"
                  title="View All"
                >
                  Back to Insights
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-100">
        <div className="row">
          {insights.map(insight => {
            let altText
            let imgUri

            if (insight.featuredImage) {
              altText = insight.featuredImage.node.altText
              imgUri = insight.featuredImage.node.mediaDetails.file
            } else {
              altText = "insights featured image"
              imgUri = "industries-fintech_u8zzb1.webp"
            }

            var theImage = cld.image(
              `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
            )

            theImage.quality("70")
            theImage.resize(fill().width(550).height(310))
            theImage.format("auto")

            let iconUse = "url(/assets/img/icon_article.svg)"
            let contentType = "Article"

            switch (insight.insights.contentType) {
              case "Podcast":
                iconUse = "url(/assets/img/icon_podcast.svg)"
                contentType = "Article"
                break
              case "Video":
                iconUse = "url(/assets/img/icon_video.svg)"
                contentType = "Video"
                break
              default:
                iconUse = "url(/assets/img/icon_article.svg)"
                contentType = "Article"
            }

            if (!insight.insights.hideFromListings) {
              return (
                <div
                  key={insight.id}
                  className="col-12 col-sm-6 col-lg-4 mb-4 mb-lg-5"
                >
                  <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                      <div style={{ overflow: "hidden" }}>
                        <Link to={insight.uri}>
                          <AdvancedImage
                            cldImg={theImage}
                            alt={altText}
                            className={`mw-100 zoomer`}
                          />
                        </Link>
                      </div>
                      <h5
                        className={`mt-3 mb-3 lower color-light-grey ${style.podcast_icon}`}
                        style={{ backgroundImage: iconUse }}
                      >
                        {contentType}
                      </h5>
                      <Link to={insight.uri}>
                        <h3 className="h4 mb-4 flex-grow-1">{insight.title}</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            } else {
              return <></>
            }
          })}
        </div>
      </div>
    </Layout>
  )
}

export default CatArchivesTemplate

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ pageContext }) => {
  const archiveSeo = {
    title: `${pageContext.name} archives | Waracle`,
    metaDesc: `Archive page for ${pageContext.name}`,
    metaRobotsNoindex: "index",
    metaRobotsNofollow: "follow",
  }

  const seoTags = seoHead(archiveSeo, "/insights/" + pageContext.slug)
  return seoTags
}
